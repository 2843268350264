<template>
    <section class="s-instagram" id="s-instagram" style="pointer-events: all;" >
        <div class="s-instagram-figure">
            <div class="s-instagram-grid" v-if="item && item.data"> 
                <div class="s-instagram-layer" style="transform: scale(1); transform-origin: 39.9% 53%;"> 
                    <div class="s-instagram-block"  :style="'background-image: url(' + $store.state.file_url +  item.data[0].banner[0].src + ')'">
                    </div> 
                </div> 
                <div class="s-instagram-layer" style="transform: scale(1.00008); transform-origin: 44.9% 50%;"> 
                    <div class="s-instagram-block" 
                        :style="'background-image: url(' + $store.state.file_url + item.data[1].banner[0].src + '?alt=media' + ')'">
                    </div> 
                </div> 
                <div class="s-instagram-layer" style="transform: scale(1.00008); transform-origin: 44.9% 50%;"> 
                    <div class="s-instagram-block" 
                        :style="'background-image: url(' + $store.state.file_url + item.data[2].banner[0].src + '?alt=media' + ')'">
                    </div> 
                </div> 
                <div class="s-instagram-layer" style="transform: scale(1); transform-origin: 44.9% 50%;"> 
                    <div class="s-instagram-block" 
                        :style="'background-image: url(' + $store.state.file_url + item.data[3].banner[0].src + '?alt=media' + ')'">
                    </div> 
                </div> 
                <div class="s-instagram-layer" style="transform: scale(1.00008); transform-origin: 44.9% 50%;"> 
                    <div class="s-instagram-block"> 
                        <a href="https://www.instagram.com/theworldofhsy/" target="_blank" rel="noopener"> 
                            <svg viewBox="0 0 20 20" fill="none"> 
                                <path d="M14.0419 0H5.95811C2.67279 0 0 2.67279 0 5.95811V14.0419C0 17.3272 2.67279 20 5.95811 20H14.0419C17.3272 20 20 17.3272 20 14.0419V5.95811C20 2.67279 17.3272 0 14.0419 0ZM17.988 14.0419C17.988 16.2212 16.2212 17.988 14.0419 17.988H5.95811C3.77875 17.988 2.012 16.2212 2.012 14.0419V5.95811C2.012 3.77871 3.77875 2.012 5.95811 2.012H14.0419C16.2212 2.012 17.988 3.77871 17.988 5.95811V14.0419Z" fill="#F3F2F5"></path> 
                                <path d="M10.0008 4.8291C7.1486 4.8291 4.82812 7.14958 4.82812 10.0018C4.82812 12.854 7.1486 15.1745 10.0008 15.1745C12.8531 15.1745 15.1735 12.854 15.1735 10.0018C15.1735 7.14954 12.8531 4.8291 10.0008 4.8291ZM10.0008 13.1625C8.25521 13.1625 6.84012 11.7474 6.84012 10.0018C6.84012 8.25619 8.25525 6.8411 10.0008 6.8411C11.7465 6.8411 13.1615 8.25619 13.1615 10.0018C13.1615 11.7474 11.7464 13.1625 10.0008 13.1625Z" fill="#F3F2F5"></path> 
                                <path d="M15.1828 6.10788C15.8674 6.10788 16.4223 5.55294 16.4223 4.86839C16.4223 4.18384 15.8674 3.62891 15.1828 3.62891C14.4983 3.62891 13.9434 4.18384 13.9434 4.86839C13.9434 5.55294 14.4983 6.10788 15.1828 6.10788Z" fill="#F3F2F5"></path> 
                            </svg> 
                        </a> 
                    </div> 
                </div> 
                <div class="s-instagram-layer" style="transform: scale(1); transform-origin: 44.9% 50%;"> 
                    <div class="s-instagram-block" 
                        :style="'background-image: url(' + $store.state.file_url + item.data[4].banner[0].src + '?alt=media' + ')'">
                    </div> 
                </div> 
                <div class="s-instagram-layer" style="transform: scale(1); transform-origin: 44.9% 50%;"> 
                    <div class="s-instagram-block" 
                    :style="'background-image: url(' + $store.state.file_url + item.data[5].banner[0].src + '?alt=media' + ')'">
                </div> 
            </div> 
            <div class="s-instagram-layer" style="transform: scale(1); transform-origin: 44.9% 50%;"> 
                <div class="s-instagram-block" 
                    :style="'background-image: url(' + $store.state.file_url + item.data[6].banner[0].src + '?alt=media' + ')'">
                </div> 
            </div> 
            <div class="s-instagram-layer" style="transform: scale(1.00008); transform-origin: 44.9% 50%;"> 
                <div class="s-instagram-block" 
                    :style="'background-image: url(' + $store.state.file_url + item.data[7].banner[0].src + '?alt=media' + ')'">
                    </div> 
                </div> 
            </div>            
            <div class="s-instagram-scroller" style="top: -40.3047px; bottom: -40.3047px;"></div> 
        </div> 
        <div class="s-instagram-text" data-effect="block">
            <h3 class="f-st">Instagram</h3>
            <h2 class="f-hm">Follow us<br /><em>@meethasyk</em></h2>
            <p class="f-pr">Become a part of our stories! Join the adventure.</p>
            <a class="f-bp" href="https://www.instagram.com/meethasyk/" target="_blank" rel="noopener">
                <svg class="f-bp-arrow" viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                </svg>
                <i class="f-bp-label">Follow Us</i>
                <svg class="f-bp-arrow" viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z" />
                </svg>
            </a>
        </div>
        <div class="s-instagram-background" 
            data-background="#f3f2f5">
        </div> 
    </section>
</template>

<script>
export default {
    props: ["item"],
    methods:{
        mouseEnter(event) {
            window.addEventListener('scroll', this.handleScroll);
        },
        handleScroll(){
            // console.log('enter')
            document.getElementById('s-instagram-grid').style.transform = 'translate3d(0px,'+(window.scrollY/10)+'px, 0px)'
        },
        mouseLeave(event) {
            // document.getElementById('s-video-badge').style.transform = 'translate3d('+650+'px, '+250+'px, 0px)';
        },
        mouseMove(event) {
            // console.log(event.clientX)
            // document.getElementById('s-video-badge').style.transform = 'translate3d('+event.clientX+'px, '+event.clientY+'px, 0px)';
        },
    }
}
</script>

<style scoped>
.s-instagram{
    margin-top: 0;
}
.s-instagram-figure{
    height: 100vh;
}
.f-pr {
    opacity: 0.8;
    font-size: 0.938vw;
    font-weight: normal;
    line-height: 1.875vw;
    letter-spacing: 0.034vw;
    font-family: 'Aktiv Grotesk';
    display: block;
    text-align: start;
    position: relative;
    transform-origin: 0px 0px;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
}
@media only screen and (max-width: 580px){
    .s-instagram-layer:nth-child(6) .s-instagram-block {
        top: 43.889vw;
        left: 73.889vw;
        width: 22.389vw;
        height: 26.389vw;
    }
    .s-instagram-layer:nth-child(3) .s-instagram-block {
        top: 30.278vw;
        left: 15px;
        width: 23.389vw;
        height: 26.389vw;
    }
    .s-instagram-figure {
        height: 42vh !important;
    }
    .s-instagram{
        margin-bottom: 0;
     }
    .s-instagram-text{
        padding-bottom: 20px;
    }
    .s-instagram-text .f-pr, .s-instagram-text .f-bp{
        margin-left: calc(100vw / 12 * 1);
        margin-right: calc(100vw / 12 * 1);
    }
    .f-pr{
        font-size: 4.167vw !important;
        line-height: 7.5vw !important;
        letter-spacing: 0.15vw !important;
    }
}

@media only screen and (min-height: 1366px){
  .s-instagram-figure{
    height: 38vh;
  }
}
@media only screen and (max-width: 768px){
  .s-instagram{
    margin-bottom: 0;  
  }
  .s-instagram-figure{
    height: 38vh !important;
  }
  .f-pr{
        font-size: 2vw;
        line-height: 2.5vw;
        letter-spacing: 0.15vw;
        margin-right: calc(100vw / 20 * 1);
    }
}
</style>